
<style scoped lang="less">
.coq_release {
  .stu-release-form {
    padding: 20px;
    .stu-release-drawer-btn {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
<template>
  <el-drawer
    custom-class="coq_release"
    title="我是标题"
    size="50%"
    :visible.sync="drawer"
  >
    <el-form
      :model="form"
      class="stu-release-form"
      :rules="rules"
      ref="stuReleaseForm"
    >
      <el-form-item label="标题" prop="title">
        <el-input
          size="small"
          v-model="form.title"
          :maxlength="30"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="简介" prop="content">
        <el-input
          size="small"
          type="textarea"
          v-model="form.content"
          :autosize="{ minRows: 4, maxRows: 6 }"
          :maxlength="250"
          show-word-limit
        ></el-input>
      </el-form-item>
      <div class="stu-release-drawer-btn">
        <el-popover placement="bottom" trigger="click">
          <el-upload
            class="upload-demo"
            action="#"
            ref="upload"
            list-type="picture-card"
            multiple
            :on-change="onChange"
            :file-list="fileList"
            :auto-upload="false"
          >
            <i slot="default" class="el-icon-plus"></i>
            <div slot="file" slot-scope="{ file }">
              <img
                class="el-upload-list__item-thumbnail"
                :src="file.url"
                style="width: 100%; height: 146px"
                alt=""
              />
              <span class="el-upload-list__item-actions">
                <span
                  class="el-upload-list__item-preview"
                  @click="handlePictureCardPreview(file)"
                >
                  <i class="el-icon-zoom-in"></i>
                </span>
                <!-- <span
                  class="el-upload-list__item-delete"
                  @click="handleDownload(file)"
                >
                  <i class="el-icon-download"></i>
                </span> -->
                <span
                  class="el-upload-list__item-delete"
                  @click="handleRemove(file)"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
            <!-- <el-button size="small" type="primary">图片上传</el-button> -->
          </el-upload>
          <p style="color: red;font-size: 14px; margin-left: 10px; font-weight: bold;">仅支持jpg,png格式</p>
          <el-button type="primary" style="margin-right: 10px" slot="reference"
            >图片上传</el-button>
        </el-popover>
        <div>
          <el-button type="primary" @click="handleConfirm('stuReleaseForm')"
            >立即创建</el-button
          >
          <el-button @click="drawer = false">取消</el-button>
        </div>
      </div>
    </el-form>
    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </el-drawer>
</template>

<script>
import { AddAllEduAnswering } from "@/libs/api/course";
export default {
  name: "coq_release",
  data() {
    return {
      drawer: false,
      rules: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        content: [{ required: true, message: "请输入简介", trigger: "blur" }],
      },
      form: {
        title: null,
        content: null,
      },
      fileList: [],
      dialogVisible: false, // 预览图片
      dialogImageUrl: null, // 预览图片
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    course() {
      return this.$store.getters.getStuMyCourse;
    },
  },
  methods: {
    handleConfirm(refName) {
      this.$refs[refName].validate((valid) => {
        if (!valid) return false;
        // if (!this.fileList.length) return this.$message.error("请选择图片");
        let data = new FormData();
        if (this.fileList.length) {
          this.fileList.map((item) => data.append("files", item.raw));
        }
        data.append("courseId", this.course.id);
        data.append("title", this.form.title);
        data.append("content", this.form.content);
        data.append("publishId", this.user.id); // 发布人
        data.append("qaType", 1); // 发布人类型 0老师 1学生
        data.append("faId", 0); // 一级答疑 发布传0 回复传父id
        AddAllEduAnswering(data).then((res) => {
          this.$message.success("发布成功");
          this.$refs["stuReleaseForm"].resetFields();
          this.drawer = false;
          this.$refs.upload.clearFiles();
          this.fileList = [];
          this.$emit("initParent");
        });
        // this.$refs.upload.submit();
      });
    },
    onChange(file, fileList) {
      this.fileList = fileList;
    },

    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      let url = URL.createObjectURL(file.raw);
      window.open(url);
    },
    // 删除选中图片
    handleRemove(file) {
      this.fileList.forEach((item, index) => {
        if (item.uid === file.uid) this.fileList.splice(index, 1);
      });
    },
  },
};
</script>
