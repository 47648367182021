import { render, staticRenderFns } from "./VideoComp.vue?vue&type=template&id=1e98334a"
import script from "./VideoComp.vue?vue&type=script&lang=js"
export * from "./VideoComp.vue?vue&type=script&lang=js"
import style0 from "./VideoComp.vue?vue&type=style&index=0&id=1e98334a&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports