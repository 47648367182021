
<style scoped lang="less">
.c_online_questions {
  background: #fff;
  .stu-module-header {
    padding: 20px;
  }
  .table-group {
    padding: 10px 20px;
  }
}
</style>
<template>
  <div class="c_online_questions">
    <div class="stu-module-header">
      <div class="stu-module-title">在线答疑</div>
      <el-button type="primary" @click="add" icon="el-icon-plus">
        发帖
      </el-button>
    </div>

    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="全部问题" name="all"></el-tab-pane>
      <el-tab-pane label="精华帖子" name="like"></el-tab-pane>
      <el-tab-pane label="我的问题" name="problem"></el-tab-pane>
      <el-tab-pane label="我的回复" name="reply"></el-tab-pane>
    </el-tabs>
    <QuestionsForm @questions_query="questions_query" />
    <div class="table-group">
      <el-table :data="tableData" v-loading="loading" :max-height="height">
        <el-table-column label="序号" :width="60" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (current - 1) * pageSize + 1 }} </span>
          </template>
        </el-table-column>
        <el-table-column
          label="标题"
          prop="title"
          :minWidth="200"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            <!-- plain dark -->
            <el-tag v-if="scope.row.del" size="mini" effect="dark"
              >已删除</el-tag
            >
            <el-button @click="detail(scope.row, scope.$index)" type="text">{{
              scope.row.title
            }}</el-button>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(c, i) in col"
          :key="i"
          :label="c.label"
          :prop="c.prop"
          :minWidth="c.width"
          :show-overflow-tooltip="c.tooltip"
          :formatter="c.formatter"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center" width="80">
          <template slot-scope="scope">
            <el-button
              type="danger"
              icon="el-icon-delete"
              @click="onDelete(scope.row, scope.$index)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        background
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <Release ref="releaseRef" @initParent="initParent" />

    <Detail :id="rowId" ref="detailRef" />
  </div>
</template>

<script>
import QuestionsForm from "./module/Form.vue";
import Release from "./module/Release.vue";
import Detail from "./module/Detail.vue";
import { QueryAllEduAnswering, UpdateEduAnswering } from "@/libs/api/course";
import { dateTimeFormat } from "@/tools/date";
export default {
  name: "c_online_questions",
  components: { QuestionsForm, Release, Detail },
  data() {
    return {
      index: 0,
      activeName: "all",
      height: 482,
      loading: false,
      queryItem: {},
      tableData: [],
      col: [
        {
          label: "回复时间",
          prop: "createTime",
          tooltip: true,
          width: 120,
          formatter: (row) => {
            if (row.createTime) {
              return dateTimeFormat(row.createTime);
            }
            return "";
          },
        },
        {
          label: "发布时间",
          prop: "publishTime",
          tooltip: true,
          width: 140,
          formatter: (row) => {
            if (row.publishTime) {
              return dateTimeFormat(row.publishTime);
            }
            return "";
          },
        },
        { label: "发布人", prop: "name" },
        { label: "浏览次数", prop: "browseNum" },
        { label: "回复次数", prop: "ansUum" },
        { label: "最后回复人", prop: "lastName", width: 100 },
        {
          label: "最后回复时间",
          prop: "updateTime",
          tooltip: true,
          width: 120,
          formatter: (row) => {
            if (row.updateTime) {
              return dateTimeFormat(row.updateTime);
            }
            return "";
          },
        },
      ],
      rowId: null,
      current: 1,
      pageSize: 10,
      total: 0,
    };
  },
  computed: {
    course() {
      return this.$store.getters.getStuMyCourse;
    },
    user() {
      return this.$store.getters.getUser;
    },
  },
  watch: {
    course(n) {
      n && this.init();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const page = {
        page: this.current,
        pageSize: this.pageSize,
      };
      const like = this.activeName === "like";
      const pr = this.activeName === "all" || this.activeName === "like";
      let filter = {};
      if (this.index === 0){
        filter = {
          publishId: pr ? undefined : this.user.id, // 学生id
          qaType: pr ? undefined : 1, // 发布人类型 0老师 1学生
        }
      }else {
        filter = {
          greateFlag: like ? 1 : 0, // 精华标识 0否 1是（精华帖子传此参数）
          publishId: pr ? undefined : this.user.id, // 学生id
          qaType: pr ? undefined : 1, // 发布人类型 0老师 1学生
        }
      }
      const params = {
        ...page,
        ...this.queryItem,
        ...filter,
        courseId: this.course.id,
        type:undefined
      };
      if (this.index === "3"){
        params.type = 3;
      }
      this.loading = true;
      QueryAllEduAnswering(params)
        .then((res) => {
          this.tableData = res.data.list;
          this.total = res.data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    initParent() {
      this.init();
    },
    add() {
      this.$refs["releaseRef"].drawer = true;
    },
    // 查询
    questions_query(item) {
      this.queryItem = item;
      this.current = 1;
      this.init();
    },
    handleClick(tab) {
      this.index = tab.index;
      this.current = 1;
      this.init();
    },

    handleCurrentChange(page) {
      if (page) {
        this.current = page;
        this.init();
      }
    },

    detail(row, index) {
      if (row) {
        this.rowId = row.id;
        this.$refs["detailRef"].drawer = true;
      }
    },
    onDelete(row, index) {
      if (row) {
        this.$confirm("确定要删除吗?", "删除提示", {
          type: "warning",
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(() => {
            const data = {
              ids: row.id,
              delFlag: 1,
              delType: 1,
            };
            UpdateEduAnswering(data).then((res) => {
              this.$message.success("删除成功");
              this.init();
            });
          })
          .catch(() => {});
      }
    },
  },
};
</script>
