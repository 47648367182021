<style lang="less">
.stu-questions-video {
  max-height: calc(100vh - 230px) !important;
  .video-player {
    // height: 100%;
    .video-js.vjs-fluid,
    .video-js.vjs-16-9,
    .video-js.vjs-4-3 {
      //   height: 100%;
    }
  }
  .options {
    .title {
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 20px;
    }
    ul {
      list-style: none;
      li {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
<template>
  <div class="stu-questions-video">
    <video-player
      class="video-player vjs-custom-skin"
      ref="videoPlayer"
      :playsinline="true"
      :options="playerOptions"
      @play="play"
      @pause="pause"
      @ended="ended"
    ></video-player>
  </div>
</template>

<script>
export default {
  name: "stu-question-video",
  props: ["url"],
  data() {
    return {
      // 视频播放
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], //可选择的播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "",
            src: "", //url地址
          },
        ],
        poster: "", //你的封面地址
        // width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, //当前时间和持续时间的分隔符
          durationDisplay: true, //显示持续时间
          remainingTimeDisplay: false, //是否显示剩余时间功能
          fullscreenToggle: true, //全屏按钮
        },
      },
    };
  },
  watch: {
    url(n) {
      this.playerOptions["sources"][0].src = n;
    },
  },
  mounted() {
    this.playerOptions["sources"][0].src = this.url;
  },
  methods: {
    // 播放
    play(player) {
      this.$emit("videoProgress", player);
    },
    // 暂停
    pause(player) {
      this.$emit("videoProgress", player);
    },
    // 播放完毕
    ended(player) {
      this.$emit("videoProgress", player);
    },
  },
};
</script>
