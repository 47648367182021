
<style scoped lang="less">
.coq_stu_detail {
  .coq_detail_container {
    padding: 20px;
    .svg-div {
      display: inline-block;
      width: 16px;
      height: 16px;
    }
    .coq_detail_title {
      font-size: 20px;
      height: 50px;
      line-height: 50px;
    }
    .coq_detail_rows_1 {
      .el-col {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        .label {
          color: #aaa;
        }
        .value {
          color: @fontColor;
        }
      }
      .flex-start {
        align-items: flex-start;
        .value {
          flex: 1;
          .for {
            display: block;
            width: 100%;
            max-height: 100px;
            overflow-y: auto;
          }
        }
      }
    }
    .coq_detail_rows_2 {
      margin-top: 20px;
      .el-col {
        .content {
          font-size: 18px;
        }
      }
      .coq_detail_func_col {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .coq_detail_func_ul {
          li {
            display: inline-block;
            height: 32px;
            line-height: 32px;
            margin-left: 15px;
            color: #aaa;
            .coq_detail_func_ul_i {
              margin-right: 3px;
            }
          }
          li:not(:nth-child(2)) {
            cursor: pointer;
          }
          .coq_detail_func_li_active {
            color: @mainColor;
          }
        }
      }

      .coq_detail_func_li_active {
        color: @mainColor;
      }
      .coq_detail_reply {
        margin-top: 10px;
      }
      .coq_detail_reply_fun {
        margin: 10px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .coq_detail_reply_btn_left {
          .input_readonly {
            width: 150px;
            margin-left: 10px;
          }
          .image-upload {
            margin-right: 10px;
          }
        }
        .coq_detail_reply_btn_right {
        }
      }
      .coq_detail_my_reply {
        color: #999;
        padding: 15px 0;
        border-bottom: 1px solid #eee;
        .coq_detail_my_reply_div {
          font-size: 18px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .coq_detail_my_reply_btn {
            margin-top: 10px;
            display: flex;
            align-items: center;
            > div {
              display: flex;
              align-items: center;
              cursor: pointer;
              .svg-div {
                line-height: 16px;
                margin-right: 5px;
              }
            }
            i {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
<template>
  <el-drawer
    custom-class="coq_stu_detail"
    title=""
    size="55%"
    :visible.sync="drawer"
  >
    <div class="coq_detail_container" v-loading="loading">
      <template v-if="items">
        <div class="coq_detail_title">{{ items.title }}</div>
        <el-row class="coq_detail_rows_1">
          <el-col :span="24">
            <p class="label">发布人：</p>
            <p class="value">{{ items.name }}</p>
          </el-col>
          <el-col :span="24" class="flex-start">
            <p class="label">资源文件：</p>
            <p class="value">
              <!-- <span class="for">
                <span
                  style="display: inline-block"
                  v-for="(item, index) in items.eduCourseResources"
                  :key="index"
                  >{{ item.resourceName }}
                </span>
                <span v-if="!items.eduCourseResources.length">暂无资源</span>
              </span> -->
              <el-button
                v-if="items.eduCourseResources.length"
                type="text"
                @click="lookResource(items.eduCourseResources)"
                >查看</el-button
              >
              <span v-else>暂无资源</span>
            </p>
          </el-col>
          <el-col :span="24">
            <p class="label">时间：</p>
            <p class="value">{{ publishTime }}</p>
          </el-col>
        </el-row>
        <el-row class="coq_detail_rows_2">
          <el-col :span="24">
            <span class="content">{{ items.content }}</span>
            <div style="margin-top: 20px">
              <viewer :images="items.eduAnwResList">
                <img
                  style="margin-right: 15px; width: 126px; height: 126px"
                  v-for="(src, index) in items.eduAnwResList"
                  :src="src.resourceUrl"
                  :key="index"
                />
              </viewer>
            </div>
          </el-col>
          <el-col :span="24" class="coq_detail_func_col">
            <el-button type="primary" @click="replySwitch = !replySwitch">
              我要回复
            </el-button>
            <ul class="coq_detail_func_ul">
              <li
                @click="replySwitch = !replySwitch"
                :class="replySwitch ? 'coq_detail_func_li_active' : ''"
              >
                <i class="el-icon-chat-dot-round coq_detail_func_ul_i"></i>
                <span>回复({{ items.ansUum }})</span>
              </li>
              <li>
                <div class="svg-div coq_detail_func_ul_i">
                  <svg-icon name="eyes"></svg-icon>
                </div>
                <span>{{ items.browseNum }}</span>
              </li>
              <li
                @click="onLike(items, null, true)"
                :class="items.status === 0 ? 'coq_detail_func_li_active' : ''"
              >
                <div class="svg-div coq_detail_func_ul_i">
                  <svg-icon name="like"></svg-icon>
                </div>
                <span>{{ items.fabuloNum }}</span>
              </li>
            </ul>
          </el-col>

          <!-- 回复 -->
          <el-col :span="24" class="coq_detail_reply" v-show="replySwitch">
            <el-input
              type="textarea"
              v-model="reply"
              placeholder="请输入回复内容"
              :autosize="{ minRows: 5, maxRows: 6 }"
              :maxlength="250"
              show-word-limit
            ></el-input>
          </el-col>
          <el-col :span="24" class="coq_detail_reply_fun" v-show="replySwitch">
            <div class="coq_detail_reply_btn_left">
              <el-popover placement="bottom" trigger="click">
                <el-upload
                  action="#"
                  style="width: 100%; height: 146px"
                  list-type="picture-card"
                  :on-change="onChange"
                  ref="upload"
                  :file-list="fileList"
                  :auto-upload="false"
                >
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{ file }">
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url"
                      alt=""
                    />
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                      >
                        <i class="el-icon-zoom-in"></i>
                      </span>
                      <!-- <span
                        class="el-upload-list__item-delete"
                        @click="handleDownload(file)"
                      >
                        <i class="el-icon-download"></i>
                      </span> -->
                      <span
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file)"
                      >
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
                <el-button type="primary" class="image-upload" slot="reference"
                  >图片上传</el-button
                >
              </el-popover>
            </div>
            <div class="coq_detail_reply_btn_right">
              <el-button @click="replySwitch = !replySwitch">取消</el-button>
              <el-button @click="onReply" :loading="btnLoading" type="primary"
                >发布</el-button
              >
            </div>
          </el-col>
          <el-col
            :span="24"
            v-for="(vos, i) in items.eduAnsweringVos"
            :key="i"
            class="coq_detail_my_reply"
          >
            <div style="margin-bottom: 10px">
              {{ vos.name }}:<span style="color: #606266; font-weight: 500">{{
                vos.content
              }}</span>
            </div>
            <viewer :images="vos.eduAnwResList">
              <img
                style="margin-right: 15px; width: 80px; height: 80px"
                v-for="(src, index) in vos.eduAnwResList"
                :src="src.resourceUrl"
                :key="index"
              />
            </viewer>
            <div class="coq_detail_my_reply_div">
              <p>时间：{{ dateFormatter(vos.publishTime) }}</p>
              <p style="width: 200px">
                资源文件：
                <el-button
                  type="text"
                  v-if="vos.eduCourseResources.length"
                  @click="lookResource(vos.eduCourseResources)"
                  >查看</el-button
                >
                <span v-else>暂无资源</span>
              </p>
              <div class="coq_detail_my_reply_btn">
                <el-icon
                  class="el-icon-delete"
                  @click.native="delReply(vos)"
                ></el-icon>
                <div style="margin: 0 10px">
                  <div class="svg-div coq_detail_func_ul_i">
                    <svg-icon name="eyes"></svg-icon>
                  </div>
                  <span>{{ vos.browseNum }}</span>
                </div>
                <div
                  @click="onLike(vos, i)"
                  :class="vos.status === 0 ? 'coq_detail_func_li_active' : ''"
                >
                  <div class="svg-div coq_detail_func_ul_i">
                    <svg-icon name="like"></svg-icon>
                  </div>
                  <span>{{ vos.fabuloNum }}</span>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </template>
    </div>
    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>

    <el-dialog title="资源文件" :visible.sync="resourceDialog" append-to-body>
      <div v-for="(item, index) in resourceList" :key="index">
        <p>
          <!-- resourceType -->
          <VideoComp v-if="isType(item) === '视频'" :url="item.resourceUrl" />
          <el-image
            v-else-if="isType(item) === '图片'"
            :src="item.resourceUrl"
          ></el-image>
          <el-link
            v-else
            type="primary"
            :href="item.resourceUrl"
            target="_blank"
            >查看</el-link
          >
        </p>
      </div>
    </el-dialog>
  </el-drawer>
</template>

<script>
import { dateTimeFormat } from "@/tools/date";
import {
  FabulousEduAnsweringByIdAndTea,
  QueryRecursionAllEduAnswering,
  UpdateEduAnswering,
  AddAllEduAnswering,
} from "@/libs/api/course";
import VideoComp from "./VideoComp.vue";
export default {
  name: "coq_stu_detail",
  components: { VideoComp },
  props: ["id"],
  data() {
    return {
      drawer: false,
      loading: false,
      btnLoading: false,
      replySwitch: false, // 回复开关

      reply: null, // 回复内容
      fileList: [],
      items: null,
      dialogVisible: false,
      dialogImageUrl: null, // 预览图片

      resourceDialog: false, // 资源文件浏览开关
      resourceList: [],
    };
  },
  computed: {
    publishTime() {
      return this.items.publishTime
        ? dateTimeFormat(this.items.publishTime)
        : "-";
    },
    course() {
      return this.$store.getters.getStuMyCourse;
    },
    user() {
      return this.$store.getters.getUser;
    },
    dateFormatter() {
      return (time) => {
        return time ? dateTimeFormat(time) : "";
      };
    },
    isType() {
      return (item) => {
        const url = item.resourceUrl;
        if (!url) return null;
        const hz = url.substr(url.length - 4, url.length);
        if (hz === ".mp4") {
          return "视频";
        } else if (".png.jpg.jpeg.PNG.JPG.JPEG".includes(resourceUrl)) {
          return "图片";
        } else {
          return "文档";
        }
      };
    },
  },
  watch: {
    id(n) {
      this.loading = true;
      n && this.detailInit();
    },
  },
  methods: {
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    onLike(item, index, flag) {
      FabulousEduAnsweringByIdAndTea({
        userId: this.user.id,
        userType: 1,
        awId: item.id,
      }).then((res) => {
        this.detailInit();
      });
    },
    delReply(item) {
      if (item) {
        this.$confirm("确定要删除吗?", "删除提示", {
          type: "warning",
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(() => {
            const data = {
              ids: item.id,
              delFlag: 1,
              delType: 1,
            };
            UpdateEduAnswering(data).then((res) => {
              this.$message.success("删除成功");
              this.detailInit();
            });
          })
          .catch(() => {});
      }
    },
    onReply() {
      if (!this.reply) return this.$message.error("请输入回复内容");
      let data = new FormData();
      if (this.fileList.length) {
        this.fileList.forEach((item) => {
          data.append("files", item.raw);
        });
      }
      data.append("courseId", this.course.id);
      data.append("title", this.items.title);
      data.append("content", this.reply);
      data.append("publishId", this.user.id); // 发布人
      data.append("qaType", 1); // 发布人类型 0老师 1学生
      data.append("faId", this.items.id); // 一级答疑 发布传0 回复传父id
      this.btnLoading = true;
      AddAllEduAnswering(data)
        .then((res) => {
          this.btnLoading = false;
          this.$message.success("发布成功");
          this.reply = "";
          this.$refs.upload.clearFiles();
          // this.drawer = false;
          this.$emit("initParent");
          this.detailInit();
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    onChange(file, fileList) {
      this.fileList = fileList;
    },
    onSuccess(res) {
      this.btnLoading = false;
      if (res.code === 2000) {
        this.$message.success("发布成功");
        this.reply = "";
        this.$refs.upload.clearFiles();
        // this.drawer = false;
        this.$emit("initParent");
        this.detailInit();
        return;
      }
      this.$message.error(res.message);
    },
    detailInit() {
      QueryRecursionAllEduAnswering({ id: this.id, type: 1 })
        .then((res) => {
          this.loading = false;
          this.items = res.data;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 下载
    // handleDownload(file) {},
    // 删除图片
    handleRemove(file) {
      this.fileList.forEach((item, index) => {
        if (item.uid === file.uid) this.fileList.splice(index, 1);
      });
    },

    lookResource(list) {
      this.resourceList = list;
      this.resourceDialog = true;
    },
  },
};
</script>
